import axios from "axios";

let apiUrl = "http://localhost:8080";

if (location.hostname === "app.simp.edu.pl" || location.hostname === "simp-app.pages.dev") {
  apiUrl = "https://app-api.simp.edu.pl";
}

let ajax = axios.create({
  baseURL: apiUrl
});

ajax.interceptors.request.use(
  config => {
    let token = localStorage.getItem("authtoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default { ...ajax };
