import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/courses",
    name: "Courses",
    component: () => import("../views/Courses.vue")
  },
  {
    path: "/courses/:id",
    name: "CoursesEdit",
    component: () => import("../views/CoursesEdit.vue")
  },
  {
    path: "/documents",
    name: "Documents",
    component: () => import("../views/Documents.vue")
  },
  {
    path: "/moodle-search",
    name: "MoodleSearch",
    component: () => import("../views/MoodleSearch.vue")
  },
  {
    path: "/moodle-add",
    name: "MoodleAdd",
    component: () => import("../views/MoodleAdd.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
