<template>
  <v-app>

    <v-navigation-drawer v-if="isSignIn"
        permanent
        app width="auto">
      <v-list nav>
          <v-list-item link to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Start</v-list-item-title>
          </v-list-item>
          <v-subheader>Szkolenia</v-subheader>
          <v-list-item link to="/courses">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lista szkoleń</v-list-item-title>
          </v-list-item>
          <v-subheader>Moodle</v-subheader>
          <v-list-item link to="/moodle-search">
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Wyszukaj użytkowników</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/moodle-add">
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dodaj nowych</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/documents">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pytania egzaminacyjne</v-list-item-title>
          </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item link @click="handleClickSignOut" :disabled="!isInit">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Wyloguj</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
    </v-navigation-drawer>
    <v-app-bar v-if="isSignIn" app color="primary" dark>
      Aplikacje SIMP-AUTOMEX
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row v-if="!isSignIn" class="mt-16" min-height="800px">
          <v-col md="12" class="text-center">
            <v-btn
                color="primary"
                @click="handleClickSignIn"
                :disabled="!isInit">
              Zaloguj z Google
              <v-icon class="ml-2">
                mdi-google
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <router-view v-if="isSignIn"/>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-col
          class="text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Copyright © SIMP-AUTOMEX</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>

import MiscService from "@/api/MiscService";

export default {
  name: "App",
  data: () => ({
    isInit: false,
    isSignIn: false,
  }),
  methods: {
    async handleClickUpdateScope() {
      const option = new window.gapi.auth2.SigninOptionsBuilder();
      option.setScope("email https://www.googleapis.com/auth/drive.file");
      const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
      try {
        await googleUser.grant(option);
        console.log("success");
      } catch (error) {
        console.error(error);
      }
    },
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log("googleUser", googleUser);
        console.log("getId", googleUser.getId());
        console.log("getBasicProfile", googleUser.getBasicProfile());
        console.log("getAuthResponse", googleUser.getAuthResponse());

        localStorage.setItem('authtoken', googleUser.getAuthResponse().id_token)
        await MiscService.me()
            .then(() => {
              this.isSignIn = this.$gAuth.isAuthorized;
            })
            .catch(error => {
              console.log(error);
              localStorage.removeItem('authtoken');
              this.isSignIn = false;
              this.$gAuth.signOut();
            });

      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        this.isSignIn = this.$gAuth.isAuthorized;
        console.log("isSignIn", this.$gAuth.isAuthorized);
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    let that = this;
    let checkGauthLoad = setInterval(function () {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);

    this.cron = setInterval(function(){
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) {
        localStorage.setItem('authtoken', that.$gAuth.GoogleAuth.currentUser.get().getAuthResponse().id_token);
      }
    }.bind(this), 10000);
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>
